$(function() {
 // 以下為Swiper設定

    // 共用Auto 
    var auplayFun = {
      delay: 3500,
      //stopOnLastSlide: false,
      disableOnInteraction: false,
    }

    // 進場動畫
    var pageFun = function(){
      var percent = 0;
      var timer = setInterval(function () {
        $(".bar").css("width", percent + "%");
        percent += 1;

        if (percent > 100) {
          clearInterval(timer);
          
          // 載完loading就把className拿掉...
          $('.js-loadHide').removeClass('js-loadHide');
          $(".pageloading").addClass("compelet");

          setTimeout(function () {
            bannerSwiper.params.autoplay = auplayFun;
            bannerSwiper.autoplay.start();
          },0);
        }
      }, 0);
    }

    if($('.bannerSwiper').length > 0) {
      // 首頁Banner
      var bannerSwiper = new Swiper('.bannerSwiper .swiper-container', {
        autoplay: auplayFun,
        parallax: true,
        keyboard : true,
        virtualTranslate : true,
        
        on:{
          setTranslate: function(){
            this.$wrapperEl.transition('')
            TweenMax.to(this.$wrapperEl, 1.5, {x:this.translate, ease:Power4.easeOut})
          }
        },
  
        pagination: {
          el: '.bannerSwiper .swiper-pagination',
          clickable: true,
        },
      });
      bannerSwiper.autoplay.stop();

      // pageFun();
    }

    // js using jquery
    setInterval(function(){
      $('.bannerSwiper .swiper-slide .txtInfo .title, .bannerSwiper .swiper-slide .txtInfo .txt,  .bannerSwiper .swiper-slide .imgInfo').addClass('animate__animated');

      $('.bannerSwiper .swiper-slide-active .txtInfo .title').addClass('animate__fadeInDown');
      $('.bannerSwiper .swiper-slide-active .txtInfo .txt').addClass('animate__fadeInUp'); 
      $('.bannerSwiper .swiper-slide-active .imgInfo').addClass('animate__fadeInRight'); 

      $(".bannerSwiper .swiper-slide-next .txtInfo .title, .swiper-slide-prev .txtInfo .title").removeClass('animate__animated, animate__fadeInDown');
      $(".bannerSwiper .swiper-slide-next .txtInfo .txt, .swiper-slide-prev .txtInfo .txt").removeClass('animate__animated, animate__fadeInUp');
      $(".bannerSwiper .swiper-slide-next .imgInfo, .swiper-slide-prev .imgInfo").removeClass('animate__animated, animate__fadeInRight');

    });

    // 首頁產品形象的輪播
    var index_pageProSwiper = new Swiper(".index-pageProSwiper .mySwiper", {
      speed:300,
      navigation: {
        nextEl: ".index-pageProSwiper .indexPro-button1-next",
        prevEl: ".index-pageProSwiper .indexPro-button1-prev",
      },
      on:{
        slideChangeTransitionStart: function(){
          // 先篩選出所有區塊且設定暫停
          indexPageProListInfo.map((el) => {
            el.autoplay.stop();
          });

          // 在當前區塊時就設定啟動
          indexPageProListInfo[this.activeIndex].autoplay.start();
        },
      },
    });

    // 首頁產品形象子項目的輪播
    var indexPageProListInfo = [];
    setTimeout(() => {
      if($('.index-pageProList-infoSwiper').length > 0) {
        $('.index-pageProList-infoSwiper').each(function(i){
          var num = i + 1;
          indexPageProListInfo.push(new Swiper('.js-indexPageProListInfo-'+num+' .mySwiper', {
            autoplay: Object.assign({}, auplayFun, {delay: 3000}),
            // $.extend({}, auplayFun, {delay: 3000}),
            speed:300,
            
            pagination: {
              el: '.js-indexPageProListInfo-'+num+' .swiper-pagination',
              clickable: true,
              type: 'fraction',
            },

            navigation: {
              nextEl: '.js-indexPageProListInfo-'+num+' .swiper-button-next',
              prevEl: '.js-indexPageProListInfo-'+num+' .swiper-button-prev',
            },
          }));
        });
      }
    }, 300)
    
    // 首頁產品列表
    var index_pageProListSwiper = new Swiper(".index-pageProList-card .mySwiper", {
      autoplay: auplayFun,
      slidesPerView: 3,
      spaceBetween: 0,
      grabCursor: true,
      pagination: {
        el: '.index-pageProList-card .swiper-pagination',
        clickable: true,
        type: 'fraction',
      },
      navigation: {
        nextEl: ".index-pageProList-card .swiper-button-next",
        prevEl: ".index-pageProList-card .swiper-button-prev",
      },
      breakpoints: { 
        320: {  //当屏幕宽度大于等于320
          slidesPerView: 1,
          spaceBetween: 0
        },
        768: {  //当屏幕宽度大于等于768 
          slidesPerView: 3,
          spaceBetween: 0,
        },
        1280: {  //当屏幕宽度大于等于1280
          slidesPerView: 3,
          spaceBetween: 0,
        }
      },
    });

    // 關於格林
    var aboutInfoCertiSwiper = new Swiper(".aboutInfo-certiSwiper .mySwiper", {
      slidesPerView: 5,
      spaceBetween: 15,
      grabCursor: true,
      navigation: {
        nextEl: ".aboutInfo-certiSwiper .swiper-button-next",
        prevEl: ".aboutInfo-certiSwiper .swiper-button-prev",
      },
      breakpoints: { 
        320: {  //当屏幕宽度大于等于320
          slidesPerView: 2,
          spaceBetween: 5
        },
        768: {  //当屏幕宽度大于等于768 
          slidesPerView: 4,
          spaceBetween: 12,
        },
        1280: {  //当屏幕宽度大于等于1280
          slidesPerView: 5,
          spaceBetween: 15,
        }
      },
    });

    var aboutInfoActSwiper = new Swiper(".aboutInfo-actSwiper .mySwiper", {
      slidesPerView: 5,
      spaceBetween: 15,
      grabCursor: true,
      navigation: {
        nextEl: ".aboutInfo-actSwiper .swiper-button-next",
        prevEl: ".aboutInfo-actSwiper .swiper-button-prev",
      },
      breakpoints: { 
        320: {  //当屏幕宽度大于等于320
          slidesPerView: 2,
          spaceBetween: 5
        },
        768: {  //当屏幕宽度大于等于768 
          slidesPerView: 4,
          spaceBetween: 12,
        },
        1280: {  //当屏幕宽度大于等于1280
          slidesPerView: 5,
          spaceBetween: 15,
        }
      },
    });

    // 產品詳細
    var shopProd_mySwiper = new Swiper(".shopProd_mySwiper", {
      autoplay: {
        delay: 2000,
        stopOnLastSlide: false,
        disableOnInteraction: true,
        pauseOnMouseEnter: true,
      },
      loop: true,
      spaceBetween: 10,
      slidesPerView: 3,
      freeMode: true,
      watchSlidesProgress: true,
      breakpoints: { 
        320: {  //当屏幕宽度大于等于320
          slidesPerView: 3,
          spaceBetween: 5
        },
        768: {  //当屏幕宽度大于等于768 
          slidesPerView: 3,
          spaceBetween: 0
        },
        1280: {  //当屏幕宽度大于等于1280
          slidesPerView: 3,
          spaceBetween: 10
        }
      }
    });

    var shopProd_mySwiper2 = new Swiper(".shopProd_mySwiper2", {
      autoplay: {
        delay: 2000,
        stopOnLastSlide: false,
        disableOnInteraction: true,
        pauseOnMouseEnter: true,
      },
      loop: true,
      spaceBetween: 10,
      // navigation: {
      //   nextEl: ".swiper-button-next",
      //   prevEl: ".swiper-button-prev",
      // },
      thumbs: {
        swiper: shopProd_mySwiper,
      },
    });


// 以下為Gotop
$(".float-link .link-btn.top, .float-link .link-btn.mobile-top").on('click',function () {
  $("html,body").animate({ "scrollTop": "0" })
});

$(window).on("load resize scroll", function (e) {
  var scroll = $(this).scrollTop();
  var wdh = $(window).height();
  if (scroll > 50) {
      $(".float-link").addClass("active");
  } else {
      $(".float-link").removeClass("active");
  }
});

// 打開搜尋bar
$('.toggle-search').on('click',function() {
  $('.search-formList').toggleClass('open');
  return false;
});

// 左側分類
toggleFun('.js-toggle-btn','.js-toggle')

// 產品側邊選單
var $slidemenu = $(".js-toggle > ul > li > a");
  $slidemenu.on('click',function(){
  // 點擊當前項目才打開
  var serToggle = $(this).closest('li').find(".first_menu");
  $(".first_menu").not(serToggle).removeClass('active');
  $slidemenu.not($(this)).removeClass('active');

  if($(this).hasClass('active')){
    $(this).removeClass('active');
    serToggle.removeClass('active');
  }else{
    $(this).addClass('active');
    serToggle.addClass('active');
  }
});

// 地圖切換
$('#contactBtn-tp').on('click',function(){
  $('#contactMap-tp').removeClass('d-none')
  $('#contactMap-tn').removeClass('d-block').addClass('d-none');

  $(this).addClass('btn-dark')
  $('#contactBtn-tn').removeClass('btn-dark').addClass('btn-outline-dark');
})

$('#contactBtn-tn').on('click',function(){
  $('#contactMap-tn').removeClass('d-none')
  $('#contactMap-tp').removeClass('d-block').addClass('d-none');

  $(this).addClass('btn-dark')
  $('#contactBtn-tp').removeClass('btn-dark').addClass('btn-outline-dark');
})

 //// ---- Swealert ---- ////
 // addTo cart
 // 參考 : https://codepen.io/designcouch/pen/OJPdZxg?editors=1100
 if($('#addtocart').length > 0) {
   $('#addtocart').on('click',function(){
     var cart = $('.cart');
     var cartTotal = cart.attr('data-totalitems');
     var newCartTotal = parseInt(cartTotal) + 1;
     Swal.fire({
       title: '商品已加入詢價清單!',
       icon: 'success',
       confirmButtonText: '關閉'
     }).then((result) => {
       setTimeout(function(){
         cart.addClass('visible');
         cart.addClass('shake').attr('data-totalitems', newCartTotal);
         setTimeout(function(){
           cart.removeClass('shake');
         },500)
       },0)
     })
   })
 }

 if($('.cartSend').length > 0) {
   $('.cartSend').on('click',function(){
     Swal.fire({
         title: '詢價清單已送出!',
         text: "",
         html:'清單送出後，將會有專人與您聯絡。',
         icon: 'success',
         confirmButtonText: '回首頁'
     }).then((result) => {
         window.location.href = "index.html";
     })
   })
 }

 // 自定義JS tab
 const tabsMenu = document.querySelectorAll('.js-tab');
 const tabsPanel = document.querySelectorAll('.js-tabs-panel');

 tabsMenu.forEach(function(data,i){
   data.addEventListener('click',function(){
     // 先定義所有區塊隱藏
     tabsMenu.forEach(function(item){
       item.classList.remove('active')
     });
     tabsPanel.forEach(function(item){
       item.classList.remove('d-block');
     });

     //當前點擊的時候..對應的索引值增加className
     tabsMenu[i].classList.add('active');
     tabsPanel[i].classList.add('d-block')
   });
 })

 //// ---- 以下為call Fun ---- ////
 startScroll();
 btnScroll('.btnScroll');
 scrollLink('.scrollLink' )
 checkEllipsis(".JQellipsis",60);
 checkEllipsis(".JQellipsis-pro",70);
});

function resize() {
  var winHeight = $(window).height();
  $(".modal-autoheight .modal-body").css({
    width: "auto",
    height: (winHeight - 200) + "px"
  });
}

function btnScroll($name){
  $($name).on('click',function() {
    if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
      var $target = $(this.hash);
      $target = $target.length && $target || $('[name=' + this.hash.slice(1) + ']');
      if ($target.length) {
        var targetOffset = $target.offset().top - 75;
        $('html,body').animate({ scrollTop: targetOffset }, 1000);
        return false;
      }
    }
  });
}

// 以下為點擊後滾動至該區塊 : '.scrollLink'  
function scrollLink($name){
  $($name).on('click', function(e) {
      e.preventDefault();
      var target = $(this).attr("href");
      
      $('html, body').stop().animate({ 
        scrollTop: $(target).offset().top
      }, 1000, function() {
        location.hash = target - 300;
      });
      
      return false;
  });
}

// 以下為主連結在滾動後的effect
function startScroll(){
  var header = $(".start-style");
  $(window).on('scroll',function() {    
    var scroll = $(window).scrollTop();
    if (scroll >= 10) {
      header.removeClass('start-style').addClass("scroll-on");
    } else {
      header.removeClass("scroll-on").addClass('start-style');
    }
  });
}

// 取得頁面寬度
function viewport() {
  var e = window,
      a = 'inner';
  if (!('innerWidth' in window)) {
      a = 'client';
      e = document.documentElement || document.body;
  }
  return { width: e[a + 'Width'], height: e[a + 'Height'] };
}

// Toggle Fun
function toggleFun(item,status){
  $(item).on('click',function() {
    $(status).toggleClass('js-open');
    return false;
  });
}

// 文字點點點
function checkEllipsis(name,len){
  $(name).each(function(i){
    if($(this).text().length>len){
        $(this).attr("title",$(this).text());
        let text=$(this).text().substring(0,len-1)+"...";
        $(this).text(text);
    }
  });
}
